import {setLocal} from '../../../../../../API/Api';
const INITIAL_STATE = {
    loading : false,
    token:"",
    fetching:false
};
const Login2Reducers =(state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'LOGIN_LOADING' :{
        return{
          ...state,
          loading:true,
          fetching:true,
        }
      }
      case 'LOGIN_SUCCESS': {
        const Token =`Bearer ${action.token.accessToken}`;
        console.log('accesstoken ',action.token.accessToken)
        localStorage.setItem('Token',Token);
        setLocal(Token);
          return {
          ...state,
          loading: false,
          token:action.token.accessToken
          };
        }
        case 'LOGIN_FAILURE':{
            return{
                ...state,
                loading:true,
                fetching:false
            }
        }
      default:
        return state;
    }
  };

export default Login2Reducers;