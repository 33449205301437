import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import { routerReducer } from 'react-router-redux';
import Login2Reducers from '../routes/form/routes/forms/components/Loginform2redux/LoginForm2Reducer';
const rootReducer = combineReducers({
  settings: settingsReducer,
  routing: routerReducer,
  Loginform2:Login2Reducers
});

export default rootReducer;
