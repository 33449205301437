import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from './Loading/index';
import './App.css';

// 3rd
import '../styles/antd.less';
import '../styles/bootstrap/bootstrap.scss';
// custom
import '../styles/layout.scss';
import '../styles/theme.scss';
import '../styles/ui.scss';
import '../styles/vendors.scss';

let AsyncAppLayout = loadable({
  loader: () => import('../components/Layout/AppLayout/index'),
  loading: LoadingComponent,
});

let AsyncAccount = loadable({
  loader: () => import('../routes/user/index'),
  loading: LoadingComponent,
});

class App extends React.Component {
  render() {
    const { match, location } = this.props;
    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      return <Redirect to={'/user/login'} />;
    }

    return (
      <div id="app">
        <Route path={`${match.url}user`} component={AsyncAccount} />
        <Route path={`${match.url}app`} component={AsyncAppLayout} />
      </div>
    );
  }
}

export default App;
